import $ from "@modules/jquery";

$(document).ready(function() {
    const popupClosed = localStorage.getItem("Video-Clicked")
    if(popupClosed) {
        const value =  JSON.parse(popupClosed).value
        if(value === 'yes') {
            $('.home .wp-block-videopopupblock-popup-player').addClass("hidden");
        }
    }else {
        $('.home .wp-block-videopopupblock-popup-player').removeClass("hidden");
    }


    $('.modal-video-close-btn').click(function() {

        $('.home .wp-block-videopopupblock-popup-player').addClass("hidden");
          var date = new Date();
          const today = (new Date()).getTime();
          date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
          localStorage.setItem('Video-Clicked', JSON.stringify({
            value: "yes",
            expDate: date,
        }));
        const popupClosed = localStorage.getItem("Video-Clicked")

        const expDate =  JSON.parse(popupClosed).expDate
        const value =  JSON.parse(popupClosed).value
      });


    $('.probleemType  select').on('change', function (e) {
        var optionSelected = $("option:selected", this);
        var text = $("option:selected", this).text();
        $('#input_2_23, #input_3_10').val(text);
    });


    $(".submit-button-2").on("click", function() {
        $('#input_2_24').val('extra');
    });
});
